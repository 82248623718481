/**
 * @file This contains the navigation component
 * @author Tom Kollmer
 */

import React from "react";
import {
  Toolbar,
  Button,
  AppBar as AppBarMui,
  Drawer as DrawerMui,
  List,
  IconButton,
  Typography,
  ListItemText,
  Avatar,
  CssBaseline,
} from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../images/fhg.png";
import profile_img from "../images/John.jpg";

import SearchIcon from "@mui/icons-material/Search";
import PostAddIcon from "@mui/icons-material/PostAdd";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DeleteIcon from "@mui/icons-material/Delete";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ShareIcon from "@mui/icons-material/Share";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuItem from "@mui/material/MenuItem";
import BallotIcon from "@mui/icons-material/Ballot";
import iconSet from "../icons/selection.json";
import IcomoonReact from "icomoon-react";
import RateReviewIcon from '@mui/icons-material/RateReview';
import "../index.css";
import "../components/Navigation.css";

/**
 * This component is used to show the toolbar and navigation menu.
 * The menu can also be minimized
 * @param {object} open react hook that contains a boolean if the navigation is open
 * @param {func} setOpen function to set the state of open
 * @returns the component
 */
export default function Navigation(props) {
  const { open, setOpen } = props;
  const [logedIn] = React.useState(true);
  const [selected, setSelected] = React.useState({ selected: "" });

  React.useEffect(() => {
    //const parsedSelected = String(localStorage.getItem("selected") || "")
    //setSelected({ selected: parsedSelected });
  }, []);

  React.useEffect(() => {
    localStorage.setItem("selected", selected.selected);
  }, [selected.selected]);

  const updateSelected = (selectedIndex) => {
    setSelected({ selected: selectedIndex });
  };

  React.useEffect(() => {
    function handleResize() {
      setOpen(window.innerWidth > 600);
    }

    window.addEventListener("resize", handleResize);
  });
  const handleClickAway = () => {
    //setOpen(false);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="root">
        <CssBaseline />
        <AppBarMui position="fixed" className="appBar">
          <Toolbar>
            <Button
              variant="text"
              color="primary"
              onClick={() => updateSelected("")}
              component={Link}
              to="/"
              className="logoPosition"
              startIcon={<img src={logo} alt="" className="logo" />}
            />
            <section className="leftToolbar">
              <Typography variant="h1"> {selected.selected} </Typography>
            </section>

            <section className="rightToolbar">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => updateSelected("")}
                edge="start"
                className="loginBtn"
                component={Link}
                to="/account"
                size="large"
              >
                <Avatar src={profile_img} className="profileImg" />
              </IconButton>
              <IconButton size="large">
                <IcomoonReact iconSet={iconSet} color="#444" icon="ausloggen" />
              </IconButton>
            </section>
          </Toolbar>
        </AppBarMui>

        <DrawerMui
          className="drawer"
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: "drawerPaper",
          }}
        >
          {logedIn ? (
            <>
              <List>
                <MenuItem
                  button
                  component={Link}
                  to="/policy/InterpretOdrlPolicy"
                  onClick={() => updateSelected("Interpret an IDS Policy")}
                  selected={selected.selected === "Interpret an IDS Policy"}
                  className="menuItem"
                >
                  <SearchIcon className="listitemicon" />

                  <ListItemText classes={{ primary: "navigation" }}>
                    Interpret Policy
                  </ListItemText>
                </MenuItem>

                <MenuItem
                  button
                  component={Link}
                  to="/policy/ComplexPolicyForm"
                  onClick={() => updateSelected("Create Policy")}
                  selected={selected.selected === "Create Policy"}
                  className="menuItem"
                >
                  <PostAddIcon className="listitemicon" />
                  <ListItemText classes={{ primary: "navigation" }}>
                    Create Policy
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  button
                  component={Link}
                  to="/policy/ProvideAccessPolicyForm"
                  onClick={() => updateSelected("Provide Access")}
                  selected={selected.selected === "Provide Access"}
                  className="menuItem"
                >
                  <LockOpenIcon className="listitemicon" />
                  <ListItemText classes={{ primary: "navigation" }}>
                    Provide Access
                  </ListItemText>
                </MenuItem>

                <MenuItem
                  button
                  component={Link}
                  id="CountAccess"
                  to="/policy/CountAccessPolicyForm"
                  onClick={() => updateSelected("Count Access")}
                  selected={selected.selected === "Count Access"}
                  className="menuItem"
                >
                  <EqualizerIcon className="listitemicon" />
                  <ListItemText classes={{ primary: "navigation" }}>
                    Count Access
                  </ListItemText>
                </MenuItem>

                <MenuItem
                  button
                  component={Link}
                  to="/policy/DeleteData"
                  onClick={() => updateSelected("Delete Data After")}
                  selected={selected.selected === "Delete Data After"}
                  className="menuItem"
                >
                  <DeleteIcon className="listitemicon" />
                  <ListItemText classes={{ primary: "navigation" }}>
                    Delete Data After
                  </ListItemText>
                </MenuItem>

                <MenuItem
                  button
                  component={Link}
                  to="/policy/AnonymizeInRestPolicyForm"
                  onClick={() => updateSelected("Anonymize in Rest")}
                  selected={selected.selected === "Anonymize in Rest"}
                  className="menuItem"
                >
                  <EnhancedEncryptionIcon className="listitemicon" />
                  <ListItemText classes={{ primary: "navigation" }}>
                    Anonymize in Rest
                  </ListItemText>
                </MenuItem>

                <MenuItem
                  button
                  component={Link}
                  to="/policy/AnonymizeInTransitPolicyForm"
                  onClick={() => updateSelected("Anonymize in Transit")}
                  selected={selected.selected === "Anonymize in Transit"}
                  className="menuItem"
                >
                  <EnhancedEncryptionIcon className="listitemicon" />
                  <ListItemText classes={{ primary: "navigation" }}>
                    Anonymize in Transit
                  </ListItemText>
                </MenuItem>

                <MenuItem
                  button
                  component={Link}
                  to="/policy/LogAccessPolicyForm"
                  onClick={() => updateSelected("Log Access")}
                  selected={selected.selected === "Log Access"}
                  className="menuItem"
                >
                  <AssignmentIcon className="listitemicon" />
                  <ListItemText classes={{ primary: "navigation" }}>
                    Log Access
                  </ListItemText>
                </MenuItem>

                <MenuItem
                  button
                  component={Link}
                  to="/policy/InformPolicyForm"
                  onClick={() => updateSelected("Inform Party")}
                  selected={selected.selected === "Inform Party"}
                  className="menuItem"
                >
                  <NotificationsActiveIcon className="listitemicon" />
                  <ListItemText classes={{ primary: "navigation" }}>
                    Inform Party
                  </ListItemText>
                </MenuItem>

                <MenuItem
                  button
                  component={Link}
                  to="/policy/DistributeData"
                  onClick={() => updateSelected("Distribute Data")}
                  selected={selected.selected === "Distribute Data"}
                  className="menuItem"
                >
                  <ShareIcon className="listitemicon" />
                  <ListItemText classes={{ primary: "navigation" }}>
                    Distribute Data
                  </ListItemText>
                </MenuItem>

                <MenuItem
                  button
                  component={Link}
                  to="/policy/ObtainConsent"
                  onClick={() => updateSelected("Obtain Consent")}
                  selected={selected.selected === "Obtain Consent"}
                  className="menuItem"
                >
                  <RateReviewIcon className="listitemicon" />
                  <ListItemText classes={{ primary: "navigation" }}>
                    Obtain Consent
                  </ListItemText>
                </MenuItem>

                <MenuItem />
              </List>
            </>
          ) : null}
        </DrawerMui>
      </div>
    </ClickAwayListener>
  );
}
