/**
 * Used to validate the user input and checks if all requirements are satisfied.
 * @param {object} values containing the user input
 * @param {object} states that determine all selected components
 * @param {func} setErrors function to show error messages on the UI
 * @returns {boolean} true when all requirements are satisfied
 */
export default function validation(values, states, setErrors) {
    let error_list = {};
    checkHeader(error_list, values);
    switch (states.page) {
      case "CreatePolicy":
        checkComplexPolicyFields(values, states, error_list);
        break;
  
      case "ProvideAccess":
        checkComplexPolicyFields(values, states, error_list);
        break;
  
      case "CountAccess":
        error_list.counter = isValidInt(values.counter);
        break;
  
      case "DeleteDataAfter":
        if (states.postduties_duration) {
          error_list.postduties_durationDay = isIntOrEmpty(
            values.postduties_durationDay
          );
          error_list.postduties_durationHour = isIntOrEmpty(
            values.postduties_durationHour
          );
          error_list.postduties_durationMonth = isIntOrEmpty(
            values.postduties_durationMonth
          );
          error_list.postduties_durationYear = isIntOrEmpty(
            values.postduties_durationYear
          );
        }
        if (states.postduties_timeDate) {
          error_list.postduties_timeAndDate = isDateOrEmpty(
            values.postduties_timeAndDate
          );
          error_list.postduties_timeAndDate = isNotEmpty(
            values.postduties_timeAndDate
          );
        }
        break;
  
      case "AnonymizeInRest":
        break;
  
      case "AnonymizeInTransit":
        error_list["preduties_modifier"] = isNotEmpty(
          values["preduties_modifier"]
        );
        error_list["preduties_fieldToChange"] = isNotEmpty(
          values["preduties_fieldToChange"]
        );
        if (values.preduties_modifier === "idsc:REPLACE") {
          error_list["preduties_valueToChange"] = isNotEmpty(
            values["preduties_valueToChange"]
          );
        }
        break;
  
      case "LogAccess":
        error_list["postduties_systemDevice"] = isValidUrl(
          values["postduties_systemDevice"]
        );
        error_list["postduties_logLevel"] = isNotEmpty(
          values["postduties_logLevel"]
        );
  
        break;
  
      case "InformParty":
        error_list["postduties_notificationLevel"] = isNotEmpty(
          values["postduties_notificationLevel"]
        );
        error_list["postduties_informedParty"] = isNotEmpty(
          values["postduties_informedParty"]
        );
        break;
  
      case "DistributeData":
        error_list.artifactState = isNotEmpty(values["artifactState"]);
        error_list.policy = isValidUrl(values["policy"]);
        break;
  
      case "ObtainConsent":
        checkMultiInputFieldNoOP("consentingParty", isNotEmpty, values, states, error_list);
        break;
  
      default:
        break;
    }
    error_list.odrlLanguageError = languageError(values.language, states, error_list)
    setErrors({
      ...error_list,
    });
    return Object.values(error_list).every((x) => x === "");
  }
  
  
  /**
   * Checks if the given input is comatible to the selected language
   * @param {*} language selected language
   * @param {*} states selected fields
   * @param {object} error_list that contains all error messages
   */
  function languageError(language, states, error_list) {
    var languageError = ""
    var errors = ""
    // ODRL 
    if (language === "ODRL"){
      const notAllowedStatesODRL = [ ];
      for (const [key, value] of Object.entries(states)) {
        if (value && notAllowedStatesODRL.includes(key)) {
          errors += `${key}, `
          //const res = Object.keys(error_list).filter(v => v.startsWith(key));
         //res.forEach(e => 
          //  error_list[e] = "Not Allowed in ODRL"
           // );
        }
      }
      if (errors !== ""){
        languageError = `This input combination is not allowed in ODRL: [${errors}]`
      }
    }
    // IDS
    return languageError
  }
  
  /**
   * Checks the user input of the complex policy page
   * @param {object} values containing the user input
   * @param {object} states that determine all selected components
   * @param {object} error_list that contains all error messages
   */
  function checkComplexPolicyFields(values, states, error_list) {
    //Restrict Application
    checkMultiInputField("application", isValidUrl, values, states, error_list);
  
    //Restrict Connector
    checkMultiInputField("connector", isValidUrl, values, states, error_list);
  
    // Restrict Number of Usage
    if (states["counter"]) {
      error_list.counter = isValidInt(values.counter);
    }
  
    //Restrict End Time
    if (states["endTime"]) {
         error_list.restrictEndTime = isValidDate(values.restrictEndTime);
       }
  
    //Restrict Start Time
    if (states["startTime"]) {
      error_list.restrictStartTime = isValidDate(values.restrictStartTime);
    }
  
    // Restrict Payment
    if (states.payment) {
      error_list.payment = isNotEmpty(values.payment);
      error_list.price = isValidFloat(values.price);
    }
  
    // Restrict Time Duration
    if (values.durationDay !== "")
      error_list.durationDay = isIntOrEmpty(values.durationDay);
    if (values.durationHour !== "")
      error_list.durationHour = isIntOrEmpty(values.durationHour);
    if (values.durationMonth !== "")
      error_list.durationMonth = isIntOrEmpty(values.durationMonth);
    if (values.durationYear !== "")
      error_list.durationYear = isIntOrEmpty(values.durationYear);
    if (values.specifyBeginTime !== "")
      error_list.specifyBeginTime = isDateOrEmpty(values.specifyBeginTime);
    //isValidDate(`${values.durationYear}-${values.durationMonth}-${values.durationDay}T${values.durationHour}:00`)
  
    // Restrict Time Interval
    if (states["interval"]) {
      error_list.restrictStartTimeInterval = isValidDate(
        values.restrictStartTimeInterval
      );
      error_list.restrictEndTimeInterval = isValidDateInterval(
        values.restrictStartTimeInterval,
        values.restrictEndTimeInterval
      );
    }
  
    // Restrict Event
    checkMultiInputField("event", isValidUrl, values, states, error_list);
  
    // Restrict Location
    checkMultiInputField("location", isNotEmpty, values, states, error_list);
  
    // Restrict Purpose
    checkMultiInputField("purpose", isNotEmpty, values, states, error_list);
  
    // Restrict Role
    checkMultiInputField("role", isNotEmpty, values, states, error_list);
  
    // Restrict Security Level
    checkMultiInputField("securityLevel", isNotEmpty, values, states, error_list);
  
    // Restrict State
    checkMultiInputField("state", isNotEmpty, values, states, error_list);
  
    // Distribute Data
    if (states["distribute"]) {
      error_list.artifactState = isNotEmpty(values["artifactState"]);
      error_list.policy = isValidUrl(values["policy"]);
    }
  
    // Anonymize in Transit
    if (states["anonymizeTransit"]) {
      error_list["preduties_modifier"] = isNotEmpty(values["preduties_modifier"]);
      error_list["preduties_fieldToChange"] = isNotEmpty(
        values["preduties_fieldToChange"]
      );
      if (values.preduties_modifier === "idsc:REPLACE") {
        error_list["preduties_valueToChange"] = isNotEmpty(
          values["preduties_valueToChange"]
        );
      }
    }
  
    // Delete Data After
    if (states.postduties_duration) {
      error_list.postduties_durationDay = isIntOrEmpty(
        values.postduties_durationDay
      );
      error_list.postduties_durationHour = isIntOrEmpty(
        values.postduties_durationHour
      );
      error_list.postduties_durationMonth = isIntOrEmpty(
        values.postduties_durationMonth
      );
      error_list.postduties_durationYear = isIntOrEmpty(
        values.postduties_durationYear
      );
    }
    if (states.postduties_timeDate) {
      error_list.postduties_timeAndDate = isDateOrEmpty(
        values.postduties_timeAndDate
      );
      error_list.postduties_timeAndDate = isNotEmpty(
        values.postduties_timeAndDate
      );
    }
  
    // Log Data Usage
    if (states["log"]) {
      error_list["postduties_systemDevice"] = isValidUrl(
        values["postduties_systemDevice"]
      );
      error_list["postduties_logLevel"] = isNotEmpty(
        values["postduties_logLevel"]
      );
    }
  
    // Inform Party
    if (states["inform"]) {
      error_list["postduties_notificationLevel"] = isNotEmpty(
        values["postduties_notificationLevel"]
      );
      error_list["postduties_informedParty"] = isNotEmpty(
        values["postduties_informedParty"]
      );
    }
  }
  
  /**
   * Checks if the user input to the header of the policy is correct
   * @param {object} error_list that contains all error messages
   * @param {object} values containing the user input
   */
  function checkHeader(error_list, values) {
    error_list.policyType = isNotEmpty(values.policyType);
    error_list.target = isValidUrl(values.target);
    if (values.policyType === "Agreement" || values.policyType === "Offer") {
      error_list.provider = isValidUrl(values.provider);
    }
    if (values.policyType === "Agreement" || values.policyType === "Request") {
      error_list.consumer = isValidUrl(values.consumer);
    }
  }
  
  /**
   * Checks if the input of a multi input field is valid
   * @param {string} key
   * @param {func} func
   * @param {object} values containing the user input
   * @param {object} states that determine all selected components
   * @param {object} error_list that contains all error messages
   */
  function checkMultiInputField(key, func, values, states, error_list) {
    if (states[key]) {
      if (1 < values[key + "_input"].length) {
        error_list[key + "_op"] = isNotEmpty(values[key + "_op"]);
      }
      for (let id = 0; id < values[key + "_input"].length; ++id) {
        error_list[key + "_input_" + id] = func(values[key + "_input"][id]);
      }
    }
  }
  
  /**
   * Checks if the input of a multi input field is valid
   * @param {string} key
   * @param {func} func
   * @param {object} values containing the user input
   * @param {object} states that determine all selected components
   * @param {object} error_list that contains all error messages
   */
  function checkMultiInputFieldNoOP(key, func, values, states, error_list) {
      for (let id = 0; id < values[key + "_input"].length; ++id) {
        error_list[key + "_input_" + id] = func(values[key + "_input"][id]);
      }
    }
  
  /**
   * Checks if the parameter is a valid url
   * @param {string} string that should be checked if it is a valid url
   * @returns {string} error message if the input is incorrect
   */
  function isValidUrl(string) {
    const error = isNotEmpty(string);
    if (error !== "") return error;
  
    try {
      new URL(string);
    } catch (_) {
      return "This is not a valid URI";
    }
    return "";
  }
  
  /**
   * Checks if the parameter is a valid date and in the future
   * @param {string} date to be checked if it is valid
   * @returns {string} error message if the input is incorrect
   */
  function isValidDate(date) {
    const error = isNotEmpty(date);
    if (error !== "") return error;
  
    try {
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      if (today <= new Date(date)) {
        return "";
      } else {
        return "The entered date is in the past";
      }
    } catch (_) {
      return "This is not a valid date";
    }
  }
  
  /**
   *  Checks if the input is a valid date and not empty
   * @param {string} date that should be checked
   * @returns {string} error message if the input is incorrect
   */
  function isDateOrEmpty(date) {
    if (date === "") return "";
    try {
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      if (today <= new Date(date)) {
        return "";
      } else {
        return "The entered date is in the past";
      }
    } catch (_) {
      return "This is not a valid date";
    }
  }
  
  /**
   * Checks if both dates define a valid interval
   * @param {string} date1 start date
   * @param {string} date2 end date
   * @returns {string} error message if the input is incorrect
   */
  function isValidDateInterval(date1, date2) {
    const error = isNotEmpty(date2);
    if (error !== "") return error;
    if (date1 === "") {
      return "The start date should not be empty";
    }
    if (new Date(date1) <= new Date(date2)) {
      return "";
    } else {
      return "The end date should be after the start date";
    }
  }
  
  /**
   * Checks if the input is a valid price
   * @param {number} price that should be checked
   * @returns {string} error message if the input is incorrect
   */
  function isValidFloat(price) {
    const error = isNotEmpty(price);
    if (error !== "") return error;
  
    if (parseFloat(price) < 0) {
      return "Price should be positive";
    } else if (parseFloat(price) >= 0) {
      return "";
    } else {
      return "Not a valid number";
    }
  }
  
  /**
   * Checks if the input is empty or a valid int
   * @param {number} n that should be checked
   * @returns {string} error message if the input is incorrect
   */
  function isIntOrEmpty(n) {
    if (n === "") return "";
    if (parseInt(n) < 0) {
      return "Count should be positive";
    } else if (parseFloat(n) >= 0) {
      return "";
    } else {
      return "Not a valid number";
    }
  }
  
  /**
   * Checks if the input is an int and not empty
   * @param {number} count that should be checked
   * @returns {string} error message if the input is incorrect
   */
  function isValidInt(count) {
    const error = isNotEmpty(count);
    if (error !== "") return error;
  
    if (parseInt(count) < 0) {
      return "Count should be positive";
    } else if (parseFloat(count) >= 0) {
      return "";
    } else {
      return "Not a valid number";
    }
  }
  
  /**
   * Checks if the string is not empty
   * @param {string} str
   * @returns {string} error message if the input is incorrect
   */
  function isNotEmpty(str) {
    if (str === "") {
      return "The field should not be empty";
    } else {
      return "";
    }
  }