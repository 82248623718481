import React, { useState } from "react";
import { Grid, Button, Paper } from "@mui/material";
import PageHeader from "../components/PageHeader";
import ShareIcon from "@mui/icons-material/Share";
import { useNavigate } from "react-router-dom";
import Form from "../components/controls/Form";
import IdentifyPolicy from "../components/controls/IdentifyPolicy";
import { OdrlPolicy } from "../components/backend/OdrlPolicy";
import Submit from "../components/backend/Submit";
import Title from "../components/controls/Title";
import { useLocation } from "react-router-dom";

import MultiSelectInputFieldNoOperator from "../components/controls/MultiSelectInputFieldNoOperator";
import "../App/App.css"

const selected_components = {
  page: "ObtainConsent",
};
export default function ObtainConsent() {
  var initialValues = OdrlPolicy();
  var stateLocal = useLocation().state;

  if (stateLocal !== null) {
    initialValues = stateLocal;
  }

  const valueHook = useState(initialValues);
  const [errors, setErrors] = useState({});
  const history = useNavigate();

  const handleClickSetODRL = (event, index) => {
    const values = valueHook[0];

    values["language"] = "ODRL";
    handleSubmit();
  };

  const handleClickSetIDS = (event, index) => {
    const values = valueHook[0];

    values["language"] = "IDS";
    handleSubmit();
  };

  const handleSubmit = (e) => {
    const values = valueHook[0];
    Submit(
      "/policy/ObtainConsentPolicyForm",
      values,
      selected_components,
      setErrors,
      history,
      e
    );
  };

  return (
    <div className="page">
      <Form>
        <PageHeader
          title="This policy gives permission to a specified IDS data consumer to use your data, however demands to obtain consent."
          icon={<ShareIcon />}
        />

        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={3} className="paperWithoutRemoveBtn">
              <IdentifyPolicy valueHook={valueHook} errors={errors} />
              <Grid container key={"consent"}>
                <Title label="Consenting Party" />
                <MultiSelectInputFieldNoOperator
                  name="consentingParty"
                  placeholder=""
                  valueHook={valueHook}
                  errors={errors}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2} xm={1}>
            <Button
              variant="contained"
              color="primary"
              className="saveBtn"
              onClick={handleClickSetIDS}
            >
              generate IDS policy
            </Button>
          </Grid>

          <Grid item xs={2} xm={1}>
            <Button
              variant="contained"
              color="primary"
              className="saveBtn"
              onClick={handleClickSetODRL}
            >
              generate ODRL policy
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
