import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import {jsonOdrlPolicy} from "../components/backend/Submit";
import CodeMirror from '@uiw/react-codemirror';
import { materialLight } from '@uiw/codemirror-theme-material';
import { json } from '@codemirror/lang-json';

import '../App/App.css';

const defaultTranslation = `The provider is an IDS party that is issuing the rule.
Here the provider is the my-party party.
This party is either the Data Owner or the Data Provider
of the specified data asset in the IDS context.
In this Policy OFFER example, the permission rule allows the
Data Consumer to use the target asset.The identifier of this
policy and the target asset are http://example.com/policy/sample
and http://example.com/ids-data/sample, respectively.`;

const defaultPolicy = '{\n \t "@context": {\n \t\t"ids":"https://w3id.org/idsa/core/",\n \t \t"idsc" : "https://w3id.org/idsa/code/"\n \t },    \n \t "@type": "ids:ContractOffer",\n \t "@id": "https://w3id.org/idsa/autogen/contract/sample",\n \t "profile": "http://example.com/ids-profile", \n \t "ids:provider": "http://example.com/party/my-party", \n \t "ids:permission": [{ \n \t "ids:target": {\n \t\t "@id":"http://example.com/ids-data/sample"\n \t }, \n \t "ids:action": [{\n \t\t "@id":"idsc:USE"\n \t \t}]\n \t }] \n  }';

export default function InterpretOdrlPolicy() {
  const [policy, setPolicy] = useState(defaultPolicy);
  const [dtPolicy, setDtPolicy] = useState(defaultTranslation);

  const transfer = () => {
    jsonOdrlPolicy("/policy/InterpretOdrlPolicy", policy, setDtPolicy);
  };

  function handleInputChange(e, setValues) {
    setValues(e);
  }

  return (
    <div className="interpret-page">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Typography variant="h5">Copy your IDS policy here:</Typography>
          </Grid>
          <Grid item xs={12}>
            <CodeMirror
              value={policy}
              extensions={[json()]}
              theme={materialLight}
              height="800px"
              onChange={(e) => {
                handleInputChange(e, setPolicy);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Typography variant="h5">Translation</Typography>
          </Grid>
          <Grid item xs={12}>
            <CodeMirror
              value={dtPolicy}
              extensions={[json()]}
              theme={materialLight}
              height="800px"
              onChange={(e) => {
                handleInputChange(e, setDtPolicy);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Button
            className="interpret-translateBtn"
            variant="contained"
            color="secondary"
            onClick={transfer}
          >
            Translate
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
