/**
 * @file This contains the log data component 
 * @author Tom Kollmer 
 */
import React from "react";
import { Grid } from "@mui/material";
import { log_level_list } from "./InitialFieldListValues";
import Input from "./Input";
import ItemPicker from "./ItemPicker";

/**
 * Components for the delete data pages
 * @component
 * @param {object} valueHook access to the user input
 * @param {object} errors contains all error messages
 * @param {number} xs size of the component at small screens
 * @param {number} sm size of the component at medium screens
 * @param {number} md size of the component at large screens
 * @param {string} prefix that should be added to the name
 * @returns component
 */
export default function LogData(props) {
  const {valueHook, errors, xs=12, sm=12, md=12, prefix=""} = props;
  return (
    <>
      <Grid item xs={xs} sm={sm} md={md}>
      <Grid container>
            <ItemPicker
              label="Log Level"
              name= {prefix + "logLevel"}
              defaultValue=""
              ItemList={log_level_list}
              valueHook={valueHook}
              errors={errors}
            />
          </Grid>

          <Grid container>
            <Input
             label="System Device"
              name={prefix + "systemDevice"}
              placeholder="e.g. http://example.com/ids/system/ESN-database"
              valueHook={valueHook}
              errors={errors}
            />
          </Grid>
      </Grid>
    </>
  );
}