import React from "react";
import { Typography, Grid, Icon } from "@mui/material";
import papEditor from "../images/PAP-Editor_Scenario(old).png";
import { loadCSS } from 'fg-loadcss';
import { Link } from "react-router-dom";

import "../App/App.css"
export default function Home() {
  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  return (
    <div className="home-container">
      <div className="home-text">
        <Typography variant="h3" className="home-title"> Data Usage Control Scenario </Typography>
        <img className="home-img" src={papEditor} alt=""></img>
        <Typography variant="h6">
          An IDS customer can be a Data Provider or a Data Consumer.
          There are variety of Data Usage Control policies that can be defined
          by data providers to protect their data.
          The Open Digital Rights Language (
          <a href="https://www.w3.org/TR/odrl-model/" className="home-link">ODRL</a>) is a policy
          expression language that provides a flexible and interoperable
          information model, vocabulary, and encoding mechanisms for
          representing statements about the usage of content and services.
        </Typography>
        <Typography variant="h6">
          <a href="https://www.w3.org/TR/odrl-model/" className="home-link">ODRL</a> policies are
          technology-independent and are not made to be technically enforced to
          a system. Therefore, it is been decided to use this language as a
          baseline for other technology-dependent solutions that can enforce
          Data Usage Control policies into the systems to protect data.
          One of the technology-dependent policy languages and platforms
          provided by{" "}
          <a href="https://www.iese.fraunhofer.de/" className="home-link">Fraunhofer IESE</a> is{" "}
          <a href="https://developer.mydata-control.de/" className="home-link">MYDATA</a>.{" "}
          <a href="https://developer.mydata-control.de/" className="home-link">MYDATA Control Technologies</a> is a
          solution that intercepts events or data flows in a system and enforces
          a security decision based on{" "}
          <a href="https://developer.mydata-control.de/" className="home-link">MYDATA</a> policies.
        </Typography>
        <br />
        <Typography variant="h4">PAP Editor</Typography>
        <Typography variant="h6">
          In this tool, we have implemented an editor that supports the data
          providers to specify their Usage Control{" "}
          <a href="https://www.w3.org/TR/odrl-model/" className="home-link">ODRL</a> policies.
          In addition, they can use this tool to translate the{" "}
          <a href="https://www.w3.org/TR/odrl-model/" className="home-link">ODRL</a> policies to{" "}
          <a href="https://developer.mydata-control.de/" className="home-link">MYDATA</a> policies
        </Typography>
        <br />
        <Typography variant="h4">
          Click{" "}
          <u>
            <Link to="/howto" className="home-link">here</Link>
          </u>{" "}
          to learn how to use this editor!
        </Typography>
        <p className="form-note">
           Please note that this website is built for demonstration purposes only. We do not store or retain any of the information you enter. However, please avoid submitting any confidential or sensitive data, as this platform is not intended for such use. If you choose to enter such information, you do so at your own risk and responsibility.
        </p>
      </div>
      <div className="home-infoBox">
        <Grid container spacing={1}>
          <Grid item xs={2} md={1} container justifyContent="center">
            <Icon className="fas fa-question" style={{ color: '#FFFFFF', fontSize:"4em" }} />
          </Grid>
          <Grid container xs={10} justifyContent="Bottom center">
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Typography variant="h6">
                {" "}
                If you have any further questions, please do not hesitate to{" "}
                <a href="https://www.mydata-control.de/#contact" className="white-link">Contact us</a>.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}